
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  margin: 0;
  padding: 0;
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;
  flex-direction: column;
  color: black;
  background-color: white;
  text-align: center;

}
body {
  position: fixed;
  left: 0; 
  top: 0; 
  right: 0; 
  bottom: 0;
}

